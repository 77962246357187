import { AllUserRoles } from "app/shared/generic_variables";
import { VillageGroup, VillageType } from "common/typings";

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCaGyu0QuH-4KnM8GUy-RL_Z_Phqyn-AKc",
    authDomain: "schoolvillage-dev-356122.firebaseapp.com",
    projectId: "schoolvillage-dev-356122",
    storageBucket: "schoolvillage-dev-356122.appspot.com",
    messagingSenderId: "395616060665",
    appId: "1:395616060665:web:264210b164bf1499d84e28",
    measurementId: "G-FF6T6WW6CW",
  },
  apiEndPoint: "https://us-central1-schoolvillage-dev-356122.cloudfunctions.net",
};

export const appName = "SchoolVillage Dev";

export const Roles = [
  AllUserRoles.district,
  AllUserRoles.pdFireEms,
  AllUserRoles.maintenance,
  AllUserRoles.admin,
  AllUserRoles.security,
  AllUserRoles.staff,
  AllUserRoles.student,
  AllUserRoles.family,
];

export const strings = {
  appName: "SchoolVillage",
  villageType: VillageType.SCHOOL,
  villageGroup: VillageGroup.District,
};

export const AllAlerts = [
  {
    key: "armed",
    value: "Armed Assailant",
    title: "An ARMED ASSAILANT has been reported",
  },
  {
    key: "fight",
    value: "Fight",
    title: "A FIGHT has been reported",
  },
  {
    key: "fire",
    value: "Fire",
    title: "A FIRE has been reported",
  },
  {
    key: "intruder",
    value: "Intruder",
    title: "An INTRUDER has been reported",
  },
  {
    key: "medical",
    value: "Medical Emergency",
    title: "A MEDICAL EMERGENCY has been reported",
  },
  {
    key: "other",
    value: "Other Emergency",
    title: "An EMERGENCY has been reported",
  },
];
export const UserBulkUploadFileSampleTemplateFileName = "sv-user-bulk-upload-sample.xlsx";
